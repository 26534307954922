import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';

export const ServerAlertContext = createContext<{
  showServerAlerts?: boolean;
  setShowServerAlerts?:
    | Dispatch<SetStateAction<boolean | undefined>>
    | (() => null);
}>({
  showServerAlerts: undefined,
  setShowServerAlerts: () => null,
});

export const ServerAlertsProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [showServerAlerts, setShowServerAlerts] = useState(undefined);
  return (
    <ServerAlertContext.Provider
      value={{ showServerAlerts, setShowServerAlerts }}
    >
      {children}
    </ServerAlertContext.Provider>
  );
};
