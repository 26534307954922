import { Alert, BodyPortal, ContentContainer } from '@codecademy/gamut';
import styled from '@emotion/styled';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeAlert } from '~/state/alerts/actions';
import { selectAlerts } from '~/state/alerts/selectors';
import { ServerAlert } from '~/state/alerts/types';

import { ServerAlertContext } from '.';

export interface ServerAlertsProps {
  extra?: ServerAlert[];
}

export const AlertArea = styled.div`
  display: grid;
  justify-content: center;
  width: 100%;
  padding: 1rem 0rem;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  top: 3.5rem;
  left: 50%;
  z-index: 15;
  position: fixed;
  transform: translate(-50%, 0);
`;

const StyledAlert = styled(Alert)`
  max-width: 100%;
`;

export const ServerAlerts: React.FC<
  React.PropsWithChildren<ServerAlertsProps>
> = ({ extra = [] }) => {
  const dispatch = useDispatch();
  const alerts = useSelector(selectAlerts);

  const allAlerts = [...alerts, ...extra];
  const { showServerAlerts, setShowServerAlerts } = useContext(
    ServerAlertContext
  );

  // This stops the server alert from flashing on page load when alerts  are disabled by the useInitialServerAlert hook
  useEffect(() => {
    const onPageLoad = () => {
      return (
        showServerAlerts === undefined &&
        setShowServerAlerts &&
        setShowServerAlerts(true)
      );
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, [showServerAlerts, setShowServerAlerts]);

  if (!allAlerts?.length) return null;

  return (
    <BodyPortal>
      {showServerAlerts && (
        <AlertArea role="alert" key="alerts">
          {allAlerts.map((alert) => (
            <ContentContainer size="medium" key={alert.message}>
              <StyledAlert
                cta={alert.cta}
                onClose={
                  alert.permanent
                    ? undefined
                    : () => {
                        dispatch(closeAlert({ message: alert.message }));
                        alert?.onClose?.();
                      }
                }
                type={alert.type}
              >
                {alert.message}
              </StyledAlert>
            </ContentContainer>
          ))}
        </AlertArea>
      )}
    </BodyPortal>
  );
};
